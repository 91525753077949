import BaseRepository from './BaseRepository';

export interface ICrudRepository<model> {
    one: model;
    all: model[];
}

export default class CrudRepository<model, saveModel = model, singleModel = model, allModel = model> extends BaseRepository<model, singleModel, allModel> {
    public save(obj: model): Promise<model> {
        const prepared = this.prepareSave(obj);
        const pk = this.getPrimaryValue(obj);
        return pk ? this.put(prepared, pk) : this.post(prepared);
    }

    public async put(obj: saveModel, pk: number): Promise<model> {
        return (await this.service.put(`${this.url}/${pk}`, obj)).data;
    }

    public async post(obj: saveModel): Promise<model> {
        return (await this.service.post(`${this.url}`, obj)).data;
    }

    public async archive(obj: model) : Promise<boolean> {
        const pk = this.getPrimaryValue(obj);
        return !!(await this.service.post(`${this.url}/${pk}/archive`, {}));
    }

    public async delete(obj: model): Promise<boolean> {
        const pk = this.getPrimaryValue(obj);
        return !!(await this.service.delete(`${this.url}/${pk}`));
    }

    public async undelete(obj: model): Promise<boolean> {
        const pk = this.getPrimaryValue(obj);
        return !!(await this.service.post(`${this.url}/${pk}/undelete`));
    }

    protected prepareSave(obj: model): saveModel {
        return (obj as any) as saveModel;
    }
}
