import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class ModalButtons extends Vue {
    @Prop({ type: Boolean }) public enableSave: boolean;
    @Prop({ type: Boolean }) public enableCancel: boolean;
    @Prop({ type: Boolean }) public enableDelete: boolean;

    public save() {
        this.$emit('save');
    }

    public cancel() {
        this.$emit('cancel');
    }

    public deleteItem() {
        this.$emit('delete');
    }
}
